<template>
  <div>
    <v-alert type="info" elevation="1" colored-border border="left">
      <p class="mb-2">
        This is where you can create specific zones for your shops, which will
        be filterable in your marketplace.
      </p>
      <p class="mb-0">
        You can also choose from labels like area, street, town, village,
        location, district and zone within your settings.
      </p>
    </v-alert>
    <div class="mt-4">
      <div v-if="selected_brand_shops.length > 0" class="py-4 text-right">
        <v-btn rounded color="yellow" class="mr-0" @click="open_add_modal()">
          <v-icon left>mdi-plus</v-icon>
          Add {{ selected_brand.zone_label }}
        </v-btn>
      </div>
      <div class="zones pb-10" v-if="selected_brand_shops.length > 0">
        <div
          class="full-height white br-8 pa-4 elevation-2"
          v-for="zone in selected_brand_zones_main"
          :key="`zone_${zone.id}`"
        >
          <div class="d-flex justify-space-between align-center mb-4">
            <h4>
              {{ zone.name }} ({{ parent_zone_shops_total_quantity(zone) }})
            </h4>
            <v-spacer />

            <v-btn icon class="mr-0" @click="open_edit_modal(zone)">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </div>
          <v-btn
            v-if="!zone.shops.length"
            rounded
            outlined
            x-small
            class="add-sub-zone-btn mb-4 font-weight-bold"
            color="black"
            @click="open_add_sub_zone_modal(zone.id)"
          >
            <v-icon x-small left>mdi-plus</v-icon>
            Add Sub-Location
          </v-btn>
          <div v-if="zone.shops.length">
            <p
              v-for="shop_id in zone.shops"
              :key="`parent_zone_shop_${shop_id}`"
              class="ml-2 mb-1 font-weight-bold primary--text text-darken-1"
            >
              {{ get_shop_name_from_id(shop_id) }}
            </p>
          </div>
          <template v-if="zone.sub_zones.length > 0">
            <div
              v-for="sub_zone in zone.sub_zones"
              :key="`sub_zone_${sub_zone.id}`"
            >
              <div class="d-flex align-center justify-space-between mb-2">
                <span> {{ sub_zone.name }} ({{ sub_zone.shops.length }}) </span>
                <v-btn icon x-small @click="open_edit_modal(sub_zone)">
                  <v-icon small>mdi-square-edit-outline</v-icon>
                </v-btn>
              </div>
              <p
                v-for="shop_id in sub_zone.shops"
                :key="`zone_shop_${shop_id}`"
                class="ml-2 mb-1 font-weight-bold primary--text text-darken-1"
              >
                {{ get_shop_name_from_id(shop_id) }}
              </p>
            </div>
          </template>
        </div>
      </div>
      <v-alert v-else type="warning" border="left" colored-border elevation="1">
        We couldn't find any shops assosiated with {{ selected_brand.name }}.
        <router-link :to="{ name: 'admin-register' }">
          Create new shop</router-link
        >
        for this brand in order to manage brand zones.
      </v-alert>
    </div>

    <!-- ADD NEW BRAND ZONE DIALOG -->

    <AdminDialog
      :show_dialog.sync="new_brand_zone_dialog"
      :header="`Add new ${
        new_brand_zone_parent_id ? 'sub-location' : selected_brand.zone_label
      }`"
      @confirm="add_new_brand_zone()"
      confirm_text="Add"
      :loading="is_adding"
      :disabled="!brand_zone_name"
    >
      <template v-slot:[`content`]>
        <v-text-field hide-details v-model="brand_zone_name" label="Name">
        </v-text-field>
      </template>
    </AdminDialog>

    <!-- EDIT BRAND ZONE DIALOG -->

    <AdminDialog
      v-if="edited_brand_zone"
      :show_dialog.sync="edit_brand_zone_dialog"
      :header="`Edit ${edited_brand_zone.name}`"
      @delete="remove_brand_zone_dialog = true"
      @confirm="edit_brand_zone(edited_brand_zone)"
      :loading="is_editing"
      confirm_text="Save"
      :disabled="!brand_zone_name"
    >
      <template v-slot:[`content`]>
        <v-text-field hide-details v-model="brand_zone_name" label="Name">
        </v-text-field>
        <div v-if="!edited_brand_zone.sub_zones.length" class="my-4">
          <h4 class="mb-2">Shops</h4>
          <v-select
            hide-details
            class="my-4 mb-6"
            multiple
            :items="selected_brand_shops"
            label="Associate Shops"
            item-text="name"
            item-value="id"
          >
            <template slot="item" slot-scope="data">
              <div
                class="full-width"
                @click.prevent.stop="
                  toggle_shop_brand_zone_association(
                    edited_brand_zone,
                    data.item
                  )
                "
              >
                <v-checkbox
                  class="mt-0 py-4"
                  hide-details
                  :input-value="edited_brand_zone.shops.includes(data.item.id)"
                  :label="data.item.name"
                />
              </div>
            </template>
          </v-select>
          <div v-if="edited_brand_zone_shops.length > 0">
            <p
              class="mb-2 caption font-weight-bold primary--text text-darken-1"
              v-for="shop in edited_brand_zone_shops"
              :key="`shop_${shop.id}`"
            >
              {{ shop.name }} ({{ shop.id }})
            </p>
          </div>
        </div>
        <div v-else class="my-4">
          <p></p>
        </div>
      </template>
    </AdminDialog>

    <!-- REMOVE BRAND ZONE DIALOG -->

    <AdminDialog
      v-if="remove_brand_zone_dialog"
      :show_dialog.sync="remove_brand_zone_dialog"
      header="Are you sure?"
      @confirm="remove_brand_zone(edited_brand_zone)"
      :loading="is_deleting"
      confirm_text="Delete"
      confirm_color="red"
    >
      <template v-slot:[`content`]>
        <p>
          Do you want to delete {{ edited_brand_zone.name }}
          {{
            edited_brand_zone.parent_id
              ? "sub-location"
              : selected_brand.zone_label
          }}?
        </p>
        <v-alert type="error" text color="red" border="left" colored-border
          >This will delete all
          {{ !edited_brand_zone.parent_id ? `sub-locations and` : "" }}
          shop associations</v-alert
        >
      </template>
    </AdminDialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import AdminDialog from "@/components/base/AdminDialog";

export default {
  name: "BrandZoneSettings",
  components: {
    AdminDialog,
  },
  data() {
    return {
      new_brand_zone_dialog: false,
      edit_brand_zone_dialog: false,
      remove_brand_zone_dialog: false,
      is_adding: false,
      is_editing: false,
      is_deleting: false,
      edited_brand_zone_id: null,
      brand_zone_name: "",
      new_brand_zone_parent_id: null,
      search: "",
    };
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand", "selected_brand_shops"]),
    selected_brand_zones_main() {
      return this.selected_brand.zones.filter((zone) => !zone.parent_id);
    },
    selected_brand_zones() {
      return [...this.selected_brand_zones_main].sort((a, b) => {
        var a_name = a.name.toUpperCase();
        var b_name = b.name.toUpperCase();
        return a_name < b_name ? -1 : a_name > b_name ? 1 : 0;
      });
    },
    edited_brand_zone() {
      return this.selected_brand.zones.find(
        (zone) => zone.id === this.edited_brand_zone_id
      );
    },
    edited_brand_zone_shops() {
      if (!this.edited_brand_zone) return [];
      return this.selected_brand_shops.filter((shop) =>
        this.edited_brand_zone.shops.includes(shop.id)
      );
    },
  },
  created() {
    this.get_brand_shops(this.$route.params.brand_id);
  },
  methods: {
    ...mapActions("SuperAdminStore", [
      "get_brand_shops",
      "add_brand_zone",
      "update_brand_zone",
      "delete_brand_zone",
      "associate_shop_with_brand_zone",
      "dissociate_shop_from_brand_zone",
    ]),
    async add_new_brand_zone() {
      this.is_adding = true;
      const params = {
        brand_id: this.selected_brand.id,
        name: this.brand_zone_name,
      };
      if (this.new_brand_zone_parent_id) {
        params.parent_id = this.new_brand_zone_parent_id;
      }

      await this.add_brand_zone({ params });
      this.new_brand_zone_dialog = false;
      this.is_adding = false;
    },
    open_edit_modal(brand_zone) {
      this.edited_brand_zone_id = brand_zone.id;
      this.edit_brand_zone_dialog = true;
      this.brand_zone_name = brand_zone.name;
    },
    open_add_sub_zone_modal(parent_id) {
      this.new_brand_zone_parent_id = parent_id;
      this.brand_zone_name = "";
      this.new_brand_zone_dialog = true;
    },
    open_add_modal() {
      this.new_brand_zone_parent_id = null;
      this.brand_zone_name = "";
      this.new_brand_zone_dialog = true;
    },
    async edit_brand_zone(brand_zone) {
      this.is_editing = true;
      const params = {
        name: this.brand_zone_name,
      };
      await this.update_brand_zone({ brand_zone_id: brand_zone.id, params });
      this.edit_brand_zone_dialog = false;
      this.edited_brand_zone_id = null;
      this.brand_zone_name = "";
      this.is_editing = false;
    },
    async remove_brand_zone(brand_zone) {
      this.is_deleting = true;
      await this.delete_brand_zone({ brand_zone_id: brand_zone.id });
      this.edit_brand_zone_dialog = false;
      this.remove_brand_zone_dialog = false;
      this.brand_zone_name = "";
      this.is_deleting = false;
    },
    toggle_shop_brand_zone_association(brand_zone, shop) {
      const shop_associated = brand_zone.shops.includes(shop.id);

      if (shop_associated) {
        this.dissociate_shop_from_brand_zone({
          brand_zone_id: brand_zone.id,
          shop_id: shop.id,
        });
      } else {
        this.associate_shop_with_brand_zone({
          brand_zone_id: brand_zone.id,
          shop_id: shop.id,
        });
      }
    },
    get_shop_name_from_id(shop_id) {
      const shop = this.selected_brand_shops.find(
        (shop) => shop.id === shop_id
      );
      if (shop) return shop.name;
      return null;
    },
    parent_zone_shops_total_quantity(zone) {
      return (
        zone.shops.length +
        zone.sub_zones.reduce((total, zone) => total + zone.shops.length, 0)
      );
    },
    sort_sub_zones(zones) {
      return zones.sort((a, b) => {
        var a_name = a.name.toUpperCase();
        var b_name = b.name.toUpperCase();
        return a_name < b_name ? -1 : a_name > b_name ? 1 : 0;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.zones {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  gap: 2rem;
  justify-content: center;
  align-content: center;
  justify-items: stretch;
  align-items: start;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    grid-template-columns: 1fr 1fr;
  }
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media #{map-get($display-breakpoints, 'lg-and-up')} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media #{map-get($display-breakpoints, 'xl-only')} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  .add-sub-zone-btn {
    border-color: var(--v-primary-base);
  }
}
</style>
